// @FIXME change review from API

import { useState, useEffect, useRef, useContext } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Autoplay } from "swiper";
import { connect } from "react-redux";

import get from "lodash/get";
import map from "lodash/map";
import moment from "moment";

import "swiper/css";
import "swiper/css/autoplay";

import { API, I18n } from "aws-amplify";
import { listReviews } from "../../src/graphql/queries";
import { I18nContext } from "components/I18N";
import { MOCK_REVIEW_ED } from "configs/mockData";

export const SwiperButtonPrev = () => {
  const swiper = useSwiper();
  return (
    <div
      onClick={() => {
        swiper.slidePrev();
      }}
      className="flex-none circle bg-[#06060608]"
    >
      <img
        alt="testimonial"
        src="/images/include/left.png"
        className="w-auto h-[12px] object-contain"
      />
    </div>
  );
};
export const SwiperButtonNext = () => {
  const swiper = useSwiper();
  return (
    <div
      onClick={() => {
        swiper.slideNext();
      }}
      className="flex-none circle bg-[#060606]"
    >
      <img
        alt="testimonial"
        src="/images/include/right.png"
        className="w-auto h-[12px] object-contain"
      />
    </div>
  );
};

export const ReviewBox = ({ id, isActive = false, item }) => {
  const { I18n, langCode } = useContext(I18nContext);
  I18n.setLanguage(langCode);
  const _containerClassName =
    "flex flex-col bg-white flex-none p-[24px] lg:p-[32px] rounded-[16px]";
  // if(isActive) _containerClassName +=  "h-[280px] mb-[100px] lg:h-[400px] lg:mb-[80px] overflow-y-auto"

  return (
    <div id={id} className={_containerClassName}>
      <p
        className={`${
          isActive ? "title" : "subtitle2"
        } font-extrabold text-[#060606] ${langCode}-font`}
      >
        {get(item.description.split("\n"), "0", "")}
      </p>
      <p
        className={`${
          isActive ? "subtitle2" : "small"
        } text-[#474747] mt-[8px] lg:mt-[16px] ${langCode}-font`}
      >
        {get(item.description.split("\n"), "1", "")}
      </p>
      <div className="flex-auto row-between-end mt-[40px]">
        <div className="w-full row-between">
          <div className="flex-1">
            <div className="row space-x-[5px]">
              {new Array(Math.floor(item.rating || 5))
                .fill(null)
                .map((item, i) => (
                  <img
                    alt="testimonial"
                    src="/images/homepage/star.png"
                    className="h-[12px] w-auto"
                    key={i}
                  />
                ))}
            </div>

            <p
              className={`${
                isActive ? "subtitle2" : "small"
              } text-[#474747] font-extrabold font-[CentraNo2] mt-[5px] ${langCode}-font`}
            >
              {item.name}
            </p>
          </div>
          <div className="row space-x-[8px]">
            <img
              alt="testimonial"
              src="/images/homepage/calendar.png"
              className="h-[15px] w-auto"
            />
            <p className="text-[#474747] small">
              {moment(item.date).format("DD/MM/YYYY")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

function List({ auth }) {
  const { I18n, langCode } = useContext(I18nContext);
  I18n.setLanguage(langCode);
  const [list, setList] = useState([]);
  const [showing, setShowing] = useState(false);

  const timeout = useRef();

  const getList = async () => {
    /** @desc FIXME */
    // setList(MOCK_REVIEW_ED[langCode] || []);
    // return;

    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(async () => {
      const result = await API.graphql({
        query: listReviews,
        variables: {
          limit: 100,
        },
        authMode: auth.user ? "AMAZON_COGNITO_USER_POOLS" : "API_KEY",
      });
      setList(get(result, "data.listReviews.items", []));
    }, 1000);
  };

  useEffect(() => {
    getList();
    setShowing(true);
  }, []);

  if (!showing) {
    return null;
  }
  if (typeof window === "undefined") {
    return <></>;
  }

  return (
    <div
      className={`relative flex-1 bg-[#F7F5F1] ${langCode}-font px-[17px] py-[48px] lg:pl-[80px] lg:py-[100px] overflow-x-hidden`}
    >
      <div className="h-full flex space-x-[40px] lg:space-x-[80px] items-start overflow-x-auto">
        <Swiper
          observer
          observeParents
          modules={[Autoplay]}
          useSwiper
          spaceBetween={40}
          slidesPerView={2}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1280: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
          }}
          loop={true}
          autoplay={{
            delay: 4000,
          }}
        >
          {map(list, (item, i) => (
            <SwiperSlide key={i}>
              {({ isActive }) => (
                <ReviewBox
                  id={`testimonial-item-${i}`}
                  isActive={isActive}
                  item={item}
                />
              )}
            </SwiperSlide>
          ))}
          <div className="flex space-x-[16px] lg:space-x-[32px] absolute left-0 bottom-0 lg:left-[55%] z-50">
            <SwiperButtonPrev />
            <SwiperButtonNext />
          </div>
          <div className="w-full h-[60px] pointetr-none" />
        </Swiper>
      </div>
    </div>
  );
}

function Testimonial({ auth }) {
  const { I18n, langCode } = useContext(I18nContext);
  I18n.setLanguage(langCode);
  return (
    <div className="row-mobile-col">
      <div className="flex flex-col w-full lg:w-[475px] bg-[#3B3F2F] text-white flex-none container-wrapper py-[48px] lg:py-[136px]">
        <h3
          className="heading6 font-black"
          dangerouslySetInnerHTML={{ __html: I18n.get("homePageReview") }}
        />
        <h4
          className="title mt-[16px] lg:my-[24px]"
          dangerouslySetInnerHTML={{ __html: I18n.get("homePageReviewDesc") }}
        />
      </div>
      <List auth={auth} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Testimonial);
