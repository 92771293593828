import { useState, useEffect, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/free-mode";
import { I18nContext } from "components/I18N";

function ProList() {
  const [showing, setShowing] = useState(false);
  const { I18n, langCode } = useContext(I18nContext);
  I18n.setLanguage(langCode);

  useEffect(() => {
    setShowing(true);
  }, []);

  if (!showing) {
    return null;
  }
  if (typeof window === "undefined") {
    return <></>;
  }

  return (
    <div
      className={`${langCode}-font w-full h-[66px] lg:h-[80px] bg-[#61E3D4] text-[#060606]`}
    >
      <Swiper
        observer
        observeParents
        modules={[Autoplay, FreeMode]}
        spaceBetween={100}
        slidesPerView={4}
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 60,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 100,
          },
        }}
        loop={true}
        freeMode={true}
        parallax={true}
        speed={16000}
        autoplay={{
          delay: 100,
        }}
      >
        <SwiperSlide>
          <div className="h-[66px] lg:h-[80px] flex items-center space-x-[8px] justify-center">
            <img
              alt="pro item"
              src="/images/homepage/easy.png"
              className="flex-none h-[33px] lg:h-[45px] h-[33px] lg:w-[45px] object-contain"
            />
            <div className="flex flex-col">
              <p className="font-bold text-[14px] lg:text-[16px]">
                {I18n.get("proListEasy")}
              </p>
              <p className="text-[12px] lg:text-[14px]">
                {I18n.get("proListEasySub")}
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="h-[66px] lg:h-[80px] flex items-center space-x-[8px] justify-center">
            <img
              alt="pro item"
              src="/images/homepage/fast.png"
              className="flex-none h-[33px] lg:h-[45px] h-[33px] lg:w-[45px] object-contain"
            />
            <div className="flex flex-col">
              <p className="font-bold text-[14px] lg:text-[16px]">
                {I18n.get("proListFast")}
              </p>
              <p className="text-[12px] lg:text-[14px]">
                {I18n.get("proListFastSub")}
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="h-[66px] lg:h-[80px] flex items-center space-x-[8px] justify-center">
            <img
              alt="pro item"
              src="/images/homepage/shipping.png"
              className="flex-none h-[33px] lg:h-[45px] h-[33px] lg:w-[45px] object-contain"
            />
            <div className="flex flex-col">
              <p className="font-bold text-[14px] lg:text-[16px]">
                {I18n.get("proListFree")}
              </p>
              <p className="text-[12px] lg:text-[14px]">
                {I18n.get("proListFreeSub")}
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="h-[66px] lg:h-[80px] flex items-center space-x-[8px] justify-center">
            <img
              alt="pro item"
              src="/images/homepage/package.png"
              className="flex-none h-[33px] lg:h-[45px] h-[33px] lg:w-[45px] object-contain"
            />
            <div className="flex flex-col">
              <p className="font-bold text-[14px] lg:text-[16px]">
                {I18n.get("proListSafe")}
              </p>
              <p className="text-[12px] lg:text-[14px]">
                {I18n.get("proListSafeSub")}
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="h-[66px] lg:h-[80px] flex items-center space-x-[8px] justify-center">
            <img
              alt="pro item"
              src="/images/homepage/package.png"
              className="flex-none h-[33px] lg:h-[45px] h-[33px] lg:w-[45px] object-contain"
            />
            <div className="flex flex-col">
              <p className="font-bold text-[14px] lg:text-[16px]">
                {I18n.get("proListFDAApproved")}
              </p>
              <p className="text-[12px] lg:text-[14px]">
                {I18n.get("proListFDAApprovedSub")}
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="h-[66px] lg:h-[80px] flex items-center space-x-[8px] justify-center">
            <img
              alt="pro item"
              src="/images/homepage/package.png"
              className="flex-none h-[33px] lg:h-[45px] h-[33px] lg:w-[45px] object-contain"
            />
            <div className="flex flex-col">
              <p className="font-bold text-[14px] lg:text-[16px]">
                {I18n.get("proListThaiLicense")}
              </p>
              <p className="text-[12px] lg:text-[14px]">
                {I18n.get("proListThaiLicenseSub")}
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="h-[66px] lg:h-[80px] flex items-center space-x-[8px] justify-center">
            <img
              alt="pro item"
              src="/images/homepage/package.png"
              className="flex-none h-[33px] lg:h-[45px] h-[33px] lg:w-[45px] object-contain"
            />
            <div className="flex flex-col">
              <p className="font-bold text-[14px] lg:text-[16px]">
                {I18n.get("proListPharmaLicense")}
              </p>
              <p className="text-[12px] lg:text-[14px]">
                {I18n.get("proListPharmaLicenseSub")}
              </p>
            </div>
          </div>
        </SwiperSlide>

        {/* proListFDAApproved: "ผลิตภัณฑ์(ยา)",
	proListFDAApprovedSub: "ได้อย.",
	proListThaiLicense: "แพทย์ ผู้เชี่ยวชาญ",
	proListThaiLicenseSub: "ดูแล",
	proListPharmaLicense: "ร้านยามีใบอนุญาต",
	proListPharmaLicenseSub: "ถูกต้อง" */}
      </Swiper>
    </div>
  );
}

export default ProList;
